<template>
  <ul>
    <li
      v-for="user in users"
      :key="user.id">
      <attach-team-user-list-item
        :user="user"
        :team="team"/>
    </li>
    <li
      v-if="users.length === 0"
      v-text="$t('attach_team_users_list.label_empty_list')"
      class="ml-4 pr-4 py-3 text-md text-gray-900">
    </li>
  </ul>
</template>

<script>
  import Team from '@/models/Team';
  import AttachTeamUserListItem from '@/components/listItems/AttachTeamUserListItem';

  export default {
    name: 'AttachTeamUsersList',
    components: {
      AttachTeamUserListItem,
    },
    props: {
      users: { type: Array, default: () => [] },
      team: { type: Team, required: true },
    },
  };
</script>
