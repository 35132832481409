var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.users, function(user) {
        return _c(
          "li",
          { key: user.id },
          [
            _c("attach-team-user-list-item", {
              attrs: { user: user, team: _vm.team }
            })
          ],
          1
        )
      }),
      _vm.users.length === 0
        ? _c("li", {
            staticClass: "ml-4 pr-4 py-3 text-md text-gray-900",
            domProps: {
              textContent: _vm._s(
                _vm.$t("attach_team_users_list.label_empty_list")
              )
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }