<template>
  <div class="flex flex-col">
    <nav class="flex flex-row px-3 mt-3 pb-2 border-b border-blue-300 items-end">
      <v-back :fallback="$router.resolve({ name: 'team-detail', params: { id } })"/>
      <p
        v-if="team !== null"
        v-text="team.name"
        class="font-semibold w-full text-center -ml-7 pointer-events-none"
      ></p>
    </nav>
    <p
      v-if="team && !team.canAddUser"
      class="form-error"
      v-text="$t('the_team_user_attach.label_can_not_add_user')"
    ></p>
    <div class="flex-grow flex flex-col">
      <div class="ml-4 py-3 pr-3 border-b border-blue-500 flex items-center">
        <button
          v-if="searchValue"
          @click="back"
          class="mr-2">
          <img
            src="@/assets/images/chevron_left.svg"
            class="h-4 w-4">
        </button>
        <form
          @submit.prevent="search"
          class="w-full">
          <input
            type="text"
            v-model="searchValue"
            :placeholder="$t('the_team_user_attach.placeholder_search_users')"
            class="form-input-blocked"
          >
        </form>
      </div>
      <v-scrollable
        v-if="!loading && !error"
        ref="scrollable">
        <attach-team-users-list
          v-if="users !== null"
          :users="users"
          :team="team"/>
      </v-scrollable>
      <v-loading v-if="loading"/>
      <v-error
        v-if="error"
        v-model="error"/>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import infinityScroll from '@/mixins/infinityScroll';
  import VError from '@/components/common/VError.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import AttachTeamUsersList from '@/components/lists/AttachTeamUsersList.vue';
  import VBack from '@/components/common/VBack.vue';

  export default {
    name: 'TheTeamUserAttach',
    components: {
      VScrollable,
      VLoading,
      VError,
      AttachTeamUsersList,
      VBack,
    },
    mixins: [infinityScroll],
    props: {
      id: { type: String, default: null },
    },
    data() {
      return {
        loading: true,
        error: null,
        searchValue: null,
      };
    },
    computed: {
      team() {
        return this.$store.getters['team/item'];
      },
      users() {
        return this.$store.getters['user/list'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    created() {
      this.loadTeam();

      this.$watch('searchValue', debounce(this.search, 500));
    },
    beforeDestroy() {
      this.$store.commit('team/clearItem');
      this.$store.commit('user/clearList');
    },
    methods: {
      loadTeam() {
        this.loading = true;

        this.$store
          .dispatch('team/loadById', this.id)
          .then(() => {
            this.search();
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      search() {
        this.error = null;

        this.detachInfiniteScroll();

        this.$store.commit('user/clearList');

        this.loading = true;
        this.loadUsers();
      },
      loadUsers() {
        this.$store
          .dispatch('user/searchForEvent', {
            eventId: this.event.id,
            searchValue: this.searchValue,
          })
          .then(() => {
            this.loading = false;

            this.$nextTick(() => {
              this.attachInfiniteScroll(this.$refs.scrollable, () => {
                this.loadUsers();
              });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      back() {
        this.searchValue = null;
      },
    },
  };
</script>
