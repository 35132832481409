var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "nav",
      {
        staticClass:
          "flex flex-row px-3 mt-3 pb-2 border-b border-blue-300 items-end"
      },
      [
        _c("v-back", {
          attrs: {
            fallback: _vm.$router.resolve({
              name: "team-detail",
              params: { id: _vm.id }
            })
          }
        }),
        _vm.team !== null
          ? _c("p", {
              staticClass:
                "font-semibold w-full text-center -ml-7 pointer-events-none",
              domProps: { textContent: _vm._s(_vm.team.name) }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.team && !_vm.team.canAddUser
      ? _c("p", {
          staticClass: "form-error",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_team_user_attach.label_can_not_add_user")
            )
          }
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex-grow flex flex-col" },
      [
        _c(
          "div",
          {
            staticClass:
              "ml-4 py-3 pr-3 border-b border-blue-500 flex items-center"
          },
          [
            _vm.searchValue
              ? _c("button", { staticClass: "mr-2", on: { click: _vm.back } }, [
                  _c("img", {
                    staticClass: "h-4 w-4",
                    attrs: { src: require("@/assets/images/chevron_left.svg") }
                  })
                ])
              : _vm._e(),
            _c(
              "form",
              {
                staticClass: "w-full",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.search($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchValue,
                      expression: "searchValue"
                    }
                  ],
                  staticClass: "form-input-blocked",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "the_team_user_attach.placeholder_search_users"
                    )
                  },
                  domProps: { value: _vm.searchValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchValue = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        !_vm.loading && !_vm.error
          ? _c(
              "v-scrollable",
              { ref: "scrollable" },
              [
                _vm.users !== null
                  ? _c("attach-team-users-list", {
                      attrs: { users: _vm.users, team: _vm.team }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.loading ? _c("v-loading") : _vm._e(),
        _vm.error
          ? _c("v-error", {
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }