var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex justify-between items-center ml-4 py-3 pr-4 border-b border-blue-500"
    },
    [
      _c("div", { staticClass: "flex items-center" }, [
        _c("div", { staticClass: "w-8" }, [
          _vm.user.avatar !== null
            ? _c("img", {
                staticClass: "h-8 rounded-full",
                attrs: {
                  src: _vm.user.avatar.medium,
                  alt: _vm.$t("general.label_profile_picture")
                }
              })
            : _c("img", {
                staticClass: "h-8 rounded-full",
                attrs: {
                  src: require("@/assets/images/avatar_placeholder.svg"),
                  alt: _vm.$t("general.label_profile_picture")
                }
              })
        ]),
        _c("div", { staticClass: "leading-tight ml-2" }, [
          _c("p", {
            staticClass: "font-semibold",
            class: { "text-gray-500": !_vm.user.onDuty },
            domProps: { textContent: _vm._s(_vm.user.name) }
          }),
          _c(
            "p",
            {
              staticClass: "text-gray-500 uppercase text-xs truncate max-w-full"
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.user.onDuty
                      ? _vm.$t("attach_team_user_list_item.label_on_duty")
                      : _vm.$t("attach_team_user_list_item.label_off_duty")
                  )
                }
              }),
              _c("span", [_vm._v(" • ")]),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("attach_team_user_list_item.label_assigned_count", {
                      amount: _vm.user.assignedIncidentsCount
                    })
                  )
                }
              })
            ]
          )
        ])
      ]),
      _c("button", {
        staticClass:
          "btn rounded-full ml-3 px-3 py-1 border-0 uppercase\n    focus:outline-none text-xs tracking-wider truncate",
        class: {
          "btn-loading": _vm.loading,
          "btn-blue": !_vm.isAttached,
          "bg-gray-200 text-blue-500": _vm.isAttached || !_vm.team.canAddUser,
          "cursor-not-allowed": !_vm.team.canAddUser
        },
        attrs: {
          disabled: !_vm.team.canAddUser || _vm.loading || _vm.isAttached
        },
        domProps: {
          textContent: _vm._s(
            !_vm.isAttached
              ? _vm.$t("general.button_attach")
              : _vm.$t("general.button_attached")
          )
        },
        on: { click: _vm.attach }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }