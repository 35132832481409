<template>
  <div class="flex justify-between items-center ml-4 py-3 pr-4 border-b border-blue-500">
    <div class="flex items-center">
      <div class="w-8">
        <img
          v-if="user.avatar !== null"
          :src="user.avatar.medium"
          :alt="$t('general.label_profile_picture')"
          class="h-8 rounded-full"
        >
        <img
          v-else
          src="@/assets/images/avatar_placeholder.svg"
          :alt="$t('general.label_profile_picture')"
          class="h-8 rounded-full"
        >
      </div>
      <div class="leading-tight ml-2">
        <p
          v-text="user.name"
          class="font-semibold"
          :class="{'text-gray-500': !user.onDuty}"></p>
        <p class="text-gray-500 uppercase text-xs truncate max-w-full">
          <span
            v-text="user.onDuty
              ? $t('attach_team_user_list_item.label_on_duty')
              : $t('attach_team_user_list_item.label_off_duty')">
          </span>
          <span> • </span>
          <span
            v-text="$t('attach_team_user_list_item.label_assigned_count', {
              amount: user.assignedIncidentsCount
            })">
          </span>
        </p>
      </div>
    </div>
    <button
      v-text="!isAttached ? $t('general.button_attach') : $t('general.button_attached')"
      class="btn rounded-full ml-3 px-3 py-1 border-0 uppercase
      focus:outline-none text-xs tracking-wider truncate"
      :class="{
        'btn-loading': loading,
        'btn-blue': !isAttached,
        'bg-gray-200 text-blue-500': isAttached || !team.canAddUser,
        'cursor-not-allowed': !team.canAddUser,
      }"
      :disabled="!team.canAddUser || loading || isAttached"
      @click="attach"
    ></button>
  </div>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep';
  import User from '@/models/User';
  import Team from '@/models/Team';
  import TeamUserRoles from '@/constants/TeamUserRoles';

  export default {
    name: 'AttachTeamUserListItem',
    props: {
      user: { type: User, required: true },
      team: { type: Team, required: true },
    },
    data() {
      return {
        isAttachedNow: false,
        loading: false,
      };
    },
    computed: {
      isAttached() {
        if (!this.team) {
          return false;
        }

        return this.team.users.some(user => user.id === this.user.id) || this.isAttachedNow;
      },
    },
    methods: {
      attach() {
        if (this.isAttached || !this.team.canAddUser) {
          return;
        }

        this.loading = true;

        const user = cloneDeep(this.user);
        user.role = TeamUserRoles.COLLABORATOR;

        this.$store
          .dispatch('user/invite', { user, team: this.team })
          .then(() => {
            this.loading = false;
            this.isAttachedNow = true;
          });
      },
    },
  };
</script>
